// MusicianContext.js
import React, { createContext, useState, useEffect } from "react";

const MusicianContext = createContext();

const icons_Idan = {
    group1: { 0: "guitar_pick", 1: "guitar_mute", 2: "guitar_fingers", 3: "guitar_strum" },
    group2: { 0: "amp_clean", 1: "space_3", 2: "amp_with_head", 3: "amp_big", 4: "square_distorted" },
};

const icons_Avior = {
    group1: { 0: "staccato", 1: "tenuto", 2: "expressive", 3: "growl" },
    group2: { 0: "M0003_g2_1_fingers", 1: "M0003_g2_2_pick", 2: "M0003_g2_3_slap", 3: "M0003_g2_4_mute" },
    group3: { 0: "M0003_g3_1_di", 1: "M0003_g3_2_drive", 2: "M0003_g3_3_octaver" },
};

export const MusicianProvider = ({ children, defaultmusician_name = "Idan Shneor" }) => {
    const [musician_name, setmusician_name] = useState(defaultmusician_name);
    const [musicianIcons, setMusicianIcons] = useState(icons_Idan);

    useEffect(() => {
        const icons = defaultmusician_name === "Idan Shneor"
            ? icons_Idan
            : defaultmusician_name === "Avior Rokach"
            ? icons_Avior
            : {};
        setMusicianIcons(icons);
    }, [defaultmusician_name]);

    return (
        <MusicianContext.Provider value={{ musician_name, setmusician_name, musicianIcons }}>
            {children}
        </MusicianContext.Provider>
    );
};

export default MusicianContext;
