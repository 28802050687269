// Parse.js
function rawDataToObject(rawData) {
    let rawRegions = rawData.musician_tracks[0].regions;

    const convertMillisecondsToSeconds = (milliseconds) => milliseconds / 1000;

    rawRegions = rawRegions.map((region) => ({
        type: region.region_type,
        start: convertMillisecondsToSeconds(region.start_time),
        end: convertMillisecondsToSeconds(region.end_time),
        specialEffects: region.performance_parameters.custom_parameter_state,
        group1: region.performance_parameters.groups?.groups1_parameters || [],
        group2: region.performance_parameters.groups?.groups2_parameters || [],
        group3: region.performance_parameters.groups?.groups3_parameters || [],
        knobs: region.performance_parameters.knobs,
        musicianId: rawData.musician_tracks[0].musician_id,
    }));

    let data = {
        songName: rawData.header.project_name,
        songId: rawData.header.project_id,
        musicianId: rawData.musician_tracks[0].musician_id,
        bpm: rawData.header.tempo,
        timeSignature: rawData.header.time_signature,
        musicKey: rawData.header.main_key.chord_string,
        regions: rawRegions,
    };

    data = secondsToBars(data);
    return data;
}

function secondsToBars(data) {
    const durationOfABarInSeconds = ((60 / data.bpm) * data.timeSignature[0]) / (data.timeSignature[1] / 4);
    const threshold = 0.1;

    for (let i = 0; i < data.regions.length; i++) {
        let startBars = data.regions[i].start / durationOfABarInSeconds;
        let endBars = data.regions[i].end / durationOfABarInSeconds;

        data.regions[i].start = startBars < threshold ? 0 : Math.round(startBars);
        data.regions[i].end = Math.round(endBars);
    }

    return data;
}

export default rawDataToObject;
