import { useState, useRef, useContext } from "react";
import { RegionComponent } from "./Page.js";
import Page from "./Page.js";
import rawDataToObject from "./Parse.js";
import WelcomeScreen from "./WelcomeScreen.js";
import MusicianContext from './MusicianContext';

const TOTAL_BOXES_IN_PAGE = 48;
const BAR_NUM = 8;

function spawnPages(regions) {
    let regionComponents = [];
    const pages = [];
    let current = 0;

    for (let i = 0; i < regions.length; i++) {
        if (Math.floor(regions[i].start / BAR_NUM) === Math.floor((regions[i].end - 1) / BAR_NUM)) {
            if (Math.floor(regions[i].end / TOTAL_BOXES_IN_PAGE) > current) {
                pages.push(regionComponents);
                current++;
                regionComponents = [];
            }
            regionComponents.push(<RegionComponent region={regions[i]} />);
        } else {
            regionComponents.push(<RegionComponent region={regions[i]} arrow="r" />);
            let lineEnd = regions[i].start + (BAR_NUM - (regions[i].start % BAR_NUM));
            if (Math.floor(lineEnd / TOTAL_BOXES_IN_PAGE) > current) {
                pages.push(regionComponents);
                current++;
                regionComponents = [];
            }
            const iterations = Math.floor(regions[i].end / BAR_NUM) - Math.floor(regions[i].start / BAR_NUM);
            for (let k = 1; k < iterations; k++) {
                regionComponents.push(<RegionComponent region={regions[i]} arrow="rl" />);
                lineEnd += 8;
                if (Math.floor(lineEnd / TOTAL_BOXES_IN_PAGE) > current) {
                    pages.push(regionComponents);
                    current++;
                    regionComponents = [];
                }
            }
            regionComponents.push(<RegionComponent region={regions[i]} arrow="l" />);
        }
    }

    pages.push(regionComponents);
    return pages;
}

function Pages({ selectedFile, onFileChange, onUploadClick, inputFile }) {
    const { setmusician_name } = useContext(MusicianContext);
    const { header } = selectedFile;
    const data = rawDataToObject(selectedFile);
    setmusician_name(header.musician_name || 'Default Musician Name'); // Update musician name
    const end = data.regions[data.regions.length - 1].end;
    const totalPages = Math.floor(end / TOTAL_BOXES_IN_PAGE) + 1;
    const pages = spawnPages(data.regions);
    const [currentPage, setCurrentPage] = useState(1);

    const handleRightClick = () => { setCurrentPage(currentPage + 1); };
    const handleLeftClick = () => { setCurrentPage(currentPage - 1); };

    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const maxSwipeDuration = 350;
    const minSwipeDistance = 40;

    const handleTouchStart = (event) => {
        setTouchEnd(null);
        setTouchStart(event.targetTouches[0].clientX);
        setStartTime(new Date().getTime());
    };

    const handleTouchMove = (event) => { setTouchEnd(event.targetTouches[0].clientX); };

    const handleTouchEnd = () => {
        if (!touchStart || !touchEnd) return;
        const duration = new Date().getTime() - startTime;
        const distance = touchStart - touchEnd;
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;
        if (duration < maxSwipeDuration) {
            if (isRightSwipe && currentPage > 1) handleLeftClick();
            else if (isLeftSwipe && currentPage < totalPages) handleRightClick();
        }
    };

    return (
        <Page
            songName={data.songName}
            songId={data.songId}
            musician_name={header.musician_name} // Ensure musician name is passed to Page
            bpm={data.bpm}
            tSign={data.timeSignature}
            sKey={data.musicKey}
            onUploadClick={onUploadClick}
            onFileChange={onFileChange}
            inputFile={inputFile}
            regions={pages[currentPage - 1]}
            currentPage={currentPage}
            totalPages={totalPages}
            onLeftClick={handleLeftClick}
            onRightClick={handleRightClick}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        />
    );
}

function Main() {
    const [selectedFile, setSelectedFile] = useState(null);
    const inputFile = useRef(null);

    function handleFileChange(file) {
        if (file) {
            setSelectedFile('between');
            if (file.name.split('.').slice(-1)[0] === 'json') {
                const reader = new FileReader();
                reader.readAsText(file, "UTF-8");
                reader.onload = (event) => {
                    setSelectedFile(JSON.parse(event.target.result));
                };
            }
        }
    }

    const handleUploadClick = () => { inputFile.current.click(); };

    if (selectedFile === 'between') return null;
    else if (selectedFile) {
        return <Pages
            selectedFile={selectedFile}
            onFileChange={(event) => handleFileChange(event.target.files[0])}
            onUploadClick={handleUploadClick}
            inputFile={inputFile}
        />;
    } else {
        return <WelcomeScreen
            onUploadClick={handleUploadClick}
            onFileChange={handleFileChange}
            inputFile={inputFile}
        />;
    }
}

export default Main;
export { Main, Pages };
